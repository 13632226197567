import Link from "next/link";
import { translate } from "@locales";
import CommonFooter from "@components/organisms/common-footer";
import CommonHeader from "@components/organisms/common-header";
import CommonLayout from "@components/templates/common-layout";
import BackgroundImage from "@assets/img/error/background-404.jpg";
import MonkeyUnsee from "@assets/img/error/monkey-unsee.png";
import MonkeyListen from "@assets/img/error/monkey-listen.png";
import MonkeyTalk from "@assets/img/error/monkey-talk.png";

interface MonkeyProps {
  src: string;
}

function MonkeyImage({ src }: MonkeyProps) {
  return (
    <div className="relative h-[50px] w-[50px] md:h-[100px] md:w-[100px]">
      <img src={src} className="h-full w-full" alt="" loading="lazy" />
    </div>
  );
}

export default function Custom404() {
  return (
    <CommonLayout
      className="flex flex-col !items-center justify-center"
      Header={<CommonHeader search hiddeBack showHomeLinks autocomplete />}
      Footer={<CommonFooter />}
    >
      <div
        className="absolute z-[1] h-full w-full bg-cover bg-blend-saturation saturate-[.3] after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-memod-header-base/30"
        style={{ backgroundImage: `url(${BackgroundImage.src})` }}
      />
      <div className="z-10 rounded-3xl bg-composer-bg p-6 text-center text-white">
        <h2 className="text-7xl font-semibold leading-tight">
          {translate("error404.title")}
        </h2>
        <div className="mb-4 flex justify-center">
          <MonkeyImage src={MonkeyUnsee.src} />
          <MonkeyImage src={MonkeyListen.src} />
          <MonkeyImage src={MonkeyTalk.src} />
        </div>
        <p>{translate("error404.description")}</p>
        <Link
          href="/"
          className="mx-auto mt-4 flex h-10 w-52 items-center justify-center rounded-full bg-memod-green-base"
        >
          {translate("backToHome")}
        </Link>
      </div>
    </CommonLayout>
  );
}
